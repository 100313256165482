
import React from 'react'

function Footer() {
  return (
    <div className='footer_container'>
    <div className='footer_content'>
    <p>All Rights Reserved @SpanCommunications</p>
    </div>
    </div>
  )
}

export default Footer