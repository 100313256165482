
// import React, { useState } from 'react';
// import { auth } from './firebase';
// import styled from 'styled-components';

// const BackgroundContainer = styled.div`
//   height: 100vh;
//   width: 100vw;
 
//   background-image: 
//     url('SpanNest_BG.png');
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   display: flex;
//   justify-content: center;
//   align-items: center;


 


// `;


// const LoginWrapper = styled.div`
  
//   padding: 20px;
//   width: 400px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   border: 1px solid gray;
//   border-radius: 20px; /* Corrected border-radius */
 
//   img {
//     width: 150px; /* Increased logo size */
//   }

//   form {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//   }

//   input {
//     margin: 10px 0;
//     padding: 10px;
//     border-radius: 5px;
//     border: 1px solid #ccc;
//   }

//   button {
//     width: 90px;
//   background: #e76f51;
//     padding: 10px 20px;
//     color: #fff;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//     font-size: 13px;
//     text-align: center; /* Center text horizontally */
//     line-height: 20px;  /* Center text vertically */
//     border: 0;
//     outline: 0;
//     border-radius: 5px;
//     cursor: pointer;
//     margin: 20px auto 0 auto; /* Center button horizontally and add top margin */
//     display: block; /* Ensure it's treated as a block-level element */
//   }

//   p {
//     color: red;
//     margin-top: 10px;
//   }
// `;

// const SignIn = ({ onSignIn }) => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
    
//     // Check credentials (this part should be handled in auth, but you may want to keep it for testing)
//     if (email === 'admin@gmail.com' && password === 'admin123') {
//       auth.signInWithEmailAndPassword(email, password)
//         .then(() => {
//           // Clear email and password fields after successful login
//           setEmail('');
//           setPassword('');
//           onSignIn();
//         })
//         .catch((error) => {
//           setError(error.message);
//         });
//     } else {
//       setError('Invalid email or password.');
//     }
//   };

//   return (
//     <BackgroundContainer>
//        <LoginWrapper>
//        <img src="SpanLogo.png" alt="Text" />
//        </LoginWrapper>
       
//        <LoginWrapper>
//        <img src="Text.png" alt="Text" />
//        </LoginWrapper>
//       <LoginWrapper>
//         <img src="spanlog.png" alt="Span Drive" />
//         <form onSubmit={handleSubmit}>
//           <input
//             type="email"
//             placeholder="Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//           <input
//             type="password"
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//           <button type="submit">Login</button>
//           {error && <p>{error}</p>}
//         </form>
//       </LoginWrapper>
    
//     </BackgroundContainer>
//   );
// };

// export default SignIn;
import React, { useState } from 'react';
import { auth } from './firebase';
import styled from 'styled-components';

const BackgroundContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url('SpanNest_BG.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const LogoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const SpanLogo = styled.img`
  opacity: 0.9;
  max-width: 100px; 
   margin-right: 170px;
  
`;

const TextImage = styled.img`
  opacity: 0.9;
  max-width: 280px; // Increased size
  
    padding-bottom: 50px;
`;

const LoginWrapper = styled.div`
  padding: 10px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid  #A10341;
  border-radius: 25px;
  background-color: #06052f;
  opacity: 0.7;
80% opacity;

  h3 {
    color: #e76f51;
    text-decoration: underline;
     padding: 10px;
  
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  input {
 
    margin: 12px 12px 0 12px;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid #A10341;
     background-color: #06052f;
     
 
}

  button {
  
    width: 150px;
    background: #FF682F;
    padding: 8px;
   
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 13px;
    text-align: center;
    line-height: 20px;
    border: 0;
    outline: 0;
    border-radius: 25px;
    cursor: pointer;
    margin: 20px auto 0 auto;
    display: block;
     color: #06052f;
  }
  input::placeholder {
  color: #fff; /* Change this to your desired color */
 
}
  p {
    color:red;
    margin-top: 10px;
  }
`;

const SignIn = ({ onSignIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (email === 'admin@gmail.com' && password === 'admin123') {
      auth.signInWithEmailAndPassword(email, password)
        .then(() => {
          setEmail('');
          setPassword('');
          onSignIn();
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      setError('Invalid email or password.');
    }
  };

  return (
    <BackgroundContainer>
      <ContentWrapper>
        <LogoTextWrapper>
          <SpanLogo src="SpanLogo.png" alt="Span Logo" />
          <TextImage src="Text.png" alt="Text" />
        </LogoTextWrapper>
        <LoginWrapper>
          <h3>NEST</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Login</button>
            {error && <p>{error}</p>}
          </form>
        </LoginWrapper>
      </ContentWrapper>
    </BackgroundContainer>
  );
};

export default SignIn;