
// import React, { useState, useEffect } from 'react';
// import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Box from '@material-ui/core/Box';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import { db } from '../firebase';
// import firebase from 'firebase/app';
// import { Menu, MenuItem, Button, IconButton, Tooltip } from '@material-ui/core';
// import FolderIcon from '@material-ui/icons/Folder';
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
// import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
// import styled from 'styled-components';
// import { useTheme, useMediaQuery } from '@material-ui/core';
// import HomeIcon from '@material-ui/icons/Home';
// import AddIcon from '@material-ui/icons/Add'; // For the New button
// import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'; // For the Create Folder option
// import UploadFileIcon from '@material-ui/icons/CloudUpload'; // For the Upload File option
// import Welcome from './Welcome';

// // Styled components
// const DataContainer = styled.div`

 
//     width: 1140px;
//     height: 1vh;
//     align-items: center;
// justify-content: center;
   
     
//        position: relative;
//      @media (max-width: 768px) {
//         padding: 10px;
//     }
//          @media (max-width: 480px) {
//         width: 100%;
//     height: 2vh;
//      padding: 0px 0px;
//     }
// `;

// const DataHeader = styled.div`

//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     height: 20px;
//     padding: 20px;
//     padding-bottom: 4px;
//     border-bottom: 0; /* Remove horizontal line */
    
//     .headerLeft {
//         display: flex;
//         align-items: center;
//     }
//     .headerRight {
//         display: flex;
//         align-items: center;
//         position: relative; /* Ensure dropdown is positioned correctly */
//     }
//           @media (max-width: 768px) {
//         flex-direction: column;
//         align-items: flex-start; /* Align items to the left */
//     }
//             @media (max-width: 480px) {
//         flex-direction: column;
//         align-items: flex-start; /* Align items to the left */
//          height: 80px;
//     padding: 2px;
//     }
// `;

// const Breadcrumbs = styled.div`
//     margin-bottom: 1px;
    
//     font-size: 18px; /* Adjusted font size for smaller screens */
    
//     display: flex;
//     align-items: center;
//     gap: 5px;
//     flex-wrap: wrap; /* Wrap breadcrumbs for smaller screens */
//       @media (max-width: 768px) {
//        font-size: 12px;
       
//     }
//        flex-wrap: wrap; /* Wrap breadcrumbs for smaller screens */
//       @media (max-width: 480px) {
//        font-size: 12px;
//         margin-top: 0px;
        
       
//     }
// `;

// const Breadcrumb = styled.span`

//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     gap: 5px;
//     &:not(:last-child)::after {
//         content: ' > ';
//     }
// `;

// const DataGrid = styled.div`

//     display: flex;
//     flex-wrap: wrap;
//     margin-top: 30px;
//     margin-bottom: 35px;
      
// `;

// const DataFile = styled.div`
   
//     text-align: left;
    
//     border: 1px solid rgb(204 204 204 / 46%);
//     margin: 8px; /* Margin for spacing */
//     min-width: 250px; /* Increased width for larger screens */
//     max-width: 150px; /* Limit maximum width */
//     padding: 2px; /* Increased padding */
//      padding-left: 8px;
//     border-radius: 10px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     cursor: pointer;
//     // background-color: #ffffff; /* White background */
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
//     svg {
//         font-size: 26px; /* Icon size */
//         color: #ADB8EB; /* Icon color */
//     }
//     .moreVertIcon {
//         color: black; /* MoreVert icon color */
//         position: absolute; /* Fix the position */
//         right: 10px; /* Align to the right */
//     }
//     p {
//         flex: 1;
//         margin-left: 20px; /* Margin for text */
//         margin-right: 5px; /* Margin for text */
//         font-size: 14px; /* Font size for larger screens */
//         font-weight: bold;
//         color: #333;
//     }
//     &:hover {
//         background-color: #d9d9d9; /* Light gray background on hover */
//     }
//     @media (max-width: 768px) {
//         min-width: 130px; /* Reduced width for medium screens */
//         max-width: 130px;
//         padding: 2px; /* Reduced padding */
//         p {
//             font-size: 12px; /* Reduced font size */
//         }
            
//     }
// @media (max-width: 480px) {
//     min-width: 140px; /* Further reduced width for small screens */
//     max-width: 10px;
//     height: 35px;
//     padding: 0; /* Remove all padding */
//     margin-top: 1px;
//     padding-left: 25px; /* Adjust the left padding for folder */
   
//     svg {
//         margin-right: 2px; /* Reduce space between icon and text */
//     }

//     p {
//         font-size: 12px;
//         padding-right: 0; /* No padding on the right */
//         margin-left: 2px; /* Reduce margin between SVG and text */
//     }
// }


// `;
// const NewButton = styled(Button)`
//     && {
       
//         float: left;

//         @media (max-width: 768px) {
//             float: right; /* Shift the button to the right */
//             margin-right: 10px; /* Add some margin for spacing */
//         }

//         @media (max-width: 480px) {
//             float: right;
//            margin-left:10px;
//             width:20px;
//             font-size:12px;
//         }
//     }
// `;




// const StyledTable = styled(Table)`
//     && {
     
//         border-collapse: collapse;
//         width: 100%; /* Full width on larger screens */
       
//         font-size: 12px;
      

//         @media (max-width: 768px) {
//             width: 90%; /* Reduce width for tablets */
//             /* Margin from the right */
//              margin-left:20px;
//         }

//         @media (max-width: 480px) {
        
//             width: 95%;
            
//             font-size: 6px; 
             
//              border-collapse: collapse;
//             margin:8px;
//         }
//     }

//     td {
  
//         border: none;
//         padding: 1px;
//         text-align: left;
//         font-size: 12px;
//         background-color: #ffffff;
//         border-bottom: 1px solid #e0e0e0;
        
//         @media (min-width: 1024px) {
//            font-size: 16px;
           
//         }
//     }

//     th {
      
//      background-color:#ADB8EB;
//         font-weight: semibold;
//         text-align: left;
    
        
//         @media (min-width: 1024px) {
//            font-size: 16px;
//         }
//     }

//     tr {
    
//         margin-bottom: 20px;
//     }

//     tr:hover td {
//         background-color: #f9f9f9;
//     }
// `;

// const FileNameCell = styled(TableCell)`

//     font-weight: normal;
//     color: #333;
   
//     @media (min-width: 1024px) {
//         min-width: 400px; /* Adjust this value to set the desired width on large screens */
      
//     }
//            @media (min-width: 480px) {
//         min-width: 400px; /* Adjust this value to set the desired width on large screens */
//     }
// `;
// const FileSizeCell = styled.td`
//   text-align: right;

//   @media (min-width: 1024px) {
//   min-width: 200px;
//   }
// `;




// const StyledMenuItem = styled(MenuItem)`

  
// `;

// const StyledLabel = styled.label`

// `;  

// const formatFileSize = (size) => {
//     if (size < 1024) return `${size} B`;
//     else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
//     else return `${(size / 1048576).toFixed(2)} MB`;
// };

// const Data = () => {
//     const [files, setFiles] = useState([]);
//     const [currentFolderId, setCurrentFolderId] = useState(null);
//     const [breadcrumbs, setBreadcrumbs] = useState([]);
//     const [menuAnchorEl, setMenuAnchorEl] = useState(null); // For "New" button dropdown
//     const [folderAnchorEl, setFolderAnchorEl] = useState(null); // For MoreVertIcon dropdown
//     const [selectedFolderId, setSelectedFolderId] = useState(null); // Track selected folder for actions
//     const isSmallScreen = useMediaQuery('(max-width: 768px)');
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [open, setOpen] = useState(false); // Control the dialog visibility
//     const [uploadProgress, setUploadProgress] = useState(0);
//     const [isUploading, setIsUploading] = useState(false);

//     const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
//     const [folderPassword, setFolderPassword] = useState('');
//     const [currentFolderPassword, setCurrentFolderPassword] = useState(null);


//     // Sort files in descending order by timestamp
//     const sortedFiles = [...files].sort((a, b) => {
//         return b.data.timestamp?.toDate() - a.data.timestamp?.toDate(); // Descending order
//     });


//     useEffect(() => {
//         db.collection("myfiles")
//             .where("parent", "==", currentFolderId)
//             .onSnapshot(snapshot => {
//                 setFiles(snapshot.docs.map(doc => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 })));
//             });
//     }, [currentFolderId]);

//     useEffect(() => {
//         const fetchBreadcrumbs = async () => {
//             let breadcrumbs = [];
//             let folderId = currentFolderId;

//             while (folderId) {
//                 const doc = await db.collection("myfiles").doc(folderId).get();
//                 const folderData = doc.data();

//                 if (folderData) {
//                     breadcrumbs.unshift({
//                         id: folderId,
//                         name: folderData.filename || 'Unnamed',
//                         isFolder: folderData.isFolder || false, // Add isFolder property here
//                     });
//                     folderId = folderData.parent;
//                 } else {
//                     folderId = null;
//                 }
//             }

//             breadcrumbs.unshift({
//                 id: null,
//                 name: 'Home',
//                 isFolder: true, // Home is considered a folder
//                 isHome: true,   // Custom flag to mark 'Home'
//             });

//             setBreadcrumbs(breadcrumbs);
//         };

//         fetchBreadcrumbs();
//     }, [currentFolderId]);


//     const handleBreadcrumbClick = (id) => {
//         setCurrentFolderId(id);
//     };

//     const handleNewButtonClick = (event) => {
//         setMenuAnchorEl(event.currentTarget);
//     };

//     const handleNewMenuClose = () => {
//         setMenuAnchorEl(null);
//     };

//     const handleCreateFolder = () => {
//         const folderName = prompt("Enter folder name:");
//         if (folderName) {
//             db.collection("myfiles").add({
//                 filename: folderName,
//                 isFolder: true,
//                 timestamp: firebase.firestore.FieldValue.serverTimestamp(),
//                 parent: currentFolderId || null, // Handle folder hierarchy
//             });
//         }
//         handleNewMenuClose(); // Close the menu after folder creation
//     };


//     const handleFileUpload = () => {
//         const file = document.getElementById('fileInput').files[0];
//         if (!file) return;

//         const storageRef = firebase.storage().ref(`files/${file.name}`);
//         const uploadTask = storageRef.put(file);

//         setIsUploading(true);
//         setOpen(true); // Open the dialog when upload starts

//         uploadTask.on(
//             'state_changed',
//             (snapshot) => {
//                 const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                 setUploadProgress(progress); // Update progress percentage
//             },
//             (error) => {
//                 console.error('Upload failed:', error);
//                 setIsUploading(false);
//                 setOpen(false); // Close the dialog on error
//             },
//             () => {
//                 uploadTask.snapshot.ref.getDownloadURL().then((url) => {
//                     firebase.firestore().collection('myfiles').add({
//                         filename: file.name,
//                         fileURL: url,
//                         size: file.size,
//                         timestamp: firebase.firestore.FieldValue.serverTimestamp(),
//                         parent: currentFolderId,
//                     });
//                     setIsUploading(false);
//                     setOpen(false); // Close the dialog when upload finishes
//                 });
//             }
//         );
//     };

//     const handleMoreVertClick = (event, folderId) => {
//         event.stopPropagation(); // Prevents the click event from propagating to the parent
//         setFolderAnchorEl(event.currentTarget);
//         setSelectedFolderId(folderId);
//     };

//     const handleFolderMenuClose = () => {
//         setFolderAnchorEl(null);
//         setSelectedFolderId(null);
//     };

//     const handleRenameFolder = () => {
//         const newName = prompt("Enter new folder name:");
//         if (newName && selectedFolderId) {
//             db.collection("myfiles").doc(selectedFolderId).update({
//                 filename: newName,
//             });
//         }
//         handleFolderMenuClose();
//     };

//     const handleDeleteFolder = () => {
//         if (window.confirm("Are you sure you want to delete this folder?") && selectedFolderId) {
//             deleteFolderAndContents(selectedFolderId);
//         }
//         handleFolderMenuClose();
//     };

//     const deleteFolderAndContents = async (folderId) => {
//         const folderRef = db.collection("myfiles").doc(folderId);
//         const subItems = await db.collection("myfiles").where("parent", "==", folderId).get();

//         for (const subItem of subItems.docs) {
//             if (subItem.data().isFolder) {
//                 await deleteFolderAndContents(subItem.id);
//             } else {
//                 await db.collection("myfiles").doc(subItem.id).delete();
//             }
//         }
//         await folderRef.delete();
//     };

//     const handleRenameFile = (fileId) => {
//         const newName = prompt("Enter new file name:");
//         if (newName) {
//             db.collection("myfiles").doc(fileId).update({
//                 filename: newName,
//             });
//         }
//     };

//     const handleDeleteFile = (fileId) => {
//         if (window.confirm("Are you sure you want to delete this file?")) {
//             db.collection("myfiles").doc(fileId).delete();
//         }
//     };

    
//     const handleDownloadFile = (fileURL, filename) => {
//     fetch(fileURL)
//     .then(response => {
//         console.log("Response status:", response.status); // Check response status
//         return response.blob();
//     })
//     .then(blob => {
//         if (blob.size === 0) {
//             throw new Error("Received empty blob");
//         }
//         const link = document.createElement('a');
//         link.href = URL.createObjectURL(blob);
//         link.download = filename;
//         link.click();
//     })
//     .catch(error => console.error('Error downloading file:', error));
// }
//     const handleViewFile = (fileURL) => {
//         window.open(fileURL, '_blank');
//         console.log("this is fileurl",fileURL);
//     };

    


//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//         console.log("this is event",event);
//         event.stopPropagation(); // Prevent the file from being opened
//     };

//     // Function to close the menu
//     const handleClose = (event) => {
//         event.stopPropagation();
//         setAnchorEl(null);
//     };
//     return (
//         <DataContainer >
//             <DataHeader >
//                 <Breadcrumbs>
//                     {breadcrumbs.map((breadcrumb) => (
//                         <React.Fragment key={breadcrumb.id || 'home'}>
//                             {breadcrumb.isHome ? (
//                                 <HomeIcon style={{ fontSize: 25, marginRight: 5, color: '#e76f51' }} /> // Home icon for 'Home'
//                             ) : (
//                                 <FolderIcon style={{ fontSize: 20, marginRight: 5, color: '#e76f51' }} /> // Folder icon for others
//                             )}
//                             <Breadcrumb
//                                 onClick={() => handleBreadcrumbClick(breadcrumb.id)}
//                                 style={{ color: '#e76f51' }} // Blue color for breadcrumb text
//                             >
//                                 {breadcrumb.name}
//                             </Breadcrumb>
//                         </React.Fragment>
//                     ))}
//                 </Breadcrumbs>
                
//                 <div className="headerRight">
//                     {/* New Button */}

//                     <NewButton
//                         variant="contained"
//                         color="primary"
//                         style={{ backgroundColor: '#e76f51', marginRight: '45px', marginTop: '25px' }} // Change the background color here
//                         onClick={handleNewButtonClick}
                        
//                         // endIcon={<ArrowDropDownIcon />}
//                         startIcon={<AddIcon />} // Add + icon here
//                     >
//                         New
//                     </NewButton>
                
//                     <Menu
//                         anchorEl={menuAnchorEl}
//                         keepMounted
//                         open={Boolean(menuAnchorEl)}
//                         onClose={handleNewMenuClose}
//                         PaperProps={{
//                             style: {
//                                 marginTop: 50,
//                             },
//                         }}
//                     >
//                         <StyledMenuItem onClick={handleCreateFolder}>
//                             <CreateNewFolderIcon style={{ marginRight: 8 }} /> 
//                             Create Folder
//                         </StyledMenuItem>
//                         {currentFolderId && (
//                             <div>
//                                 <StyledMenuItem>
//                                     <UploadFileIcon style={{ marginRight: 8 }} />
//                                     <StyledLabel htmlFor="fileInput">Upload File</StyledLabel>
//                                     <input
//                                         type="file"
//                                         id="fileInput"
//                                         style={{ display: 'none' }}
//                                         onChange={handleFileUpload}
//                                     />
//                                 </StyledMenuItem>
//                                 {/* Dialog for showing upload progress */}
//                                 <Dialog open={open} onClose={handleClose} aria-labelledby="upload-dialog-title">
//                                     <DialogTitle id="upload-dialog-title">Uploading File</DialogTitle>
//                                     <DialogContent>
//                                         <Box sx={{ width: '100%', mt: 1 }}>
//                                             <LinearProgress
//                                                 variant="determinate"
//                                                 value={uploadProgress}
//                                                 sx={{
//                                                     height: 40, // Increase the height of the progress bar
//                                                     borderRadius: 5, // Rounded corners
//                                                     backgroundColor: '#e0f7e0', // Light green background
//                                                     '& .MuiLinearProgress-bar': {
//                                                         backgroundColor: '#4caf50', // Green progress bar
//                                                         borderRadius: 5, // Rounded corners for the progress bar
//                                                     },
//                                                 }}
//                                             />
//                                             <Typography
//                                                 variant="body2"
//                                                 color="textSecondary"
//                                                 align="center"
//                                                 style={{ marginTop: '8px' }}
//                                             >
//                                                 {Math.round(uploadProgress)}% {/* Display numeric progress */}
//                                             </Typography>
//                                         </Box>
//                                     </DialogContent>
//                                 </Dialog>
//                             </div>
//                         )} 
//                     </Menu>
//                 </div>
              
//             </DataHeader>
//             <div className='welcome_container'     style={{ marginTop: '12px', alignItems:'center', justifyContent:'center' }}>
//                 <div className='welcome_content'> 
//                 <Welcome/>
//                  </div>
//             </div>
          
//             <DataGrid>
          
//                 {sortedFiles.filter(file => file.data.isFolder).map(file => (
//                     <DataFile key={file.id} onClick={() => setCurrentFolderId(file.id)}>
//                         <FolderIcon />
//                         <p>
//                             {isSmallScreen
//                                 ? (file.data.filename.length > 8 ? `${file.data.filename.substring(0, 8)}...` : file.data.filename)
//                                 : (file.data.filename.length > 20 ? `${file.data.filename.substring(0, 20)}...` : file.data.filename)}
//                         </p>
//                         <Tooltip title="More options">
//                             <IconButton onClick={(event) => handleMoreVertClick(event, file.id)}>
//                                 <MoreVertIcon />
//                             </IconButton>
//                         </Tooltip>
//                     </DataFile>
//                 ))}
//             </DataGrid>
          
// {sortedFiles.filter(file => !file.data.isFolder).length > 0 ? (
//   <table className="simple-table">
//     <thead>
//       <tr>
//         <th>File Name</th>
//         <th>Size</th>
//         <th>Date</th>
//         <th>Actions</th>
//       </tr>
//     </thead>
//     <tbody>
//       {sortedFiles.filter(file => !file.data.isFolder).map(file => (
//         <tr key={file.id} onClick={() => handleViewFile(file.data.fileURL)}>
//           <td>
//             <div className="file-name">
//               <InsertDriveFileIcon style={{ color: ' #ADB8EB' }} />
//               <span>
//                 {isSmallScreen
//                   ? (file.data.filename.length > 8 ? `${file.data.filename.substring(0, 8)}...` : file.data.filename)
//                   : file.data.filename}
//               </span>
//             </div>
//           </td>
//           <td>{formatFileSize(file.data.size)}</td>
//           <td>{new Date(file.data.timestamp?.toDate()).toLocaleDateString()}</td>
//           <td>
//             <div className="action-buttons">
//               {isSmallScreen ? (
//                 <>
//                   <MoreVertIcon onClick={handleClick} />
                 
//                   <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  
//                     <MenuItem onClick={() => { handleRenameFile(file.id); handleClose(); }}> <EditIcon /></MenuItem>
//                     <MenuItem onClick={() => { handleDeleteFile(file.id); handleClose(); }}>   <DeleteIcon /></MenuItem>
//                     <MenuItem onClick={() => { handleDownloadFile(file.data.fileURL, file.data.filename); handleClose(); }}><ArrowDownwardIcon /></MenuItem>
//                   </Menu>
//                 </>
//               ) : (
//                 <>   <Tooltip title="Rename">
//                 <IconButton onClick={(event) => { event.stopPropagation(); handleRenameFile(file.id); }} className="action-icons">
//                     <EditIcon />
//                 </IconButton>
//             </Tooltip>
//             <Tooltip title="Delete">
//                 <IconButton onClick={(event) => { event.stopPropagation(); handleDeleteFile(file.id); }} className="action-icons">
//                     <DeleteIcon />
//                 </IconButton>
//             </Tooltip>
//             <Tooltip title="Download">
//                 <IconButton onClick={(event) => { event.stopPropagation(); handleDownloadFile(file.data.fileURL, file.data.filename); }} className="action-icons">
//                     <ArrowDownwardIcon />
//                 </IconButton>
//             </Tooltip>
//                 </>
//               )}
//             </div>
//           </td>
//         </tr>
//       ))}
//     </tbody>
    
//   </table>
// ) : (currentFolderId && <p>No files</p>)} 

//             {/* Folder actions menu */}
//             <Menu
//                 anchorEl={folderAnchorEl}
//                 keepMounted
//                 open={Boolean(folderAnchorEl)}
//                 onClose={handleFolderMenuClose}
//                 PaperProps={{
//                     style: {
//                       marginTop: 60,
//                     },
//                 }}
//             >
//                 <MenuItem onClick={handleRenameFolder}>Rename </MenuItem>
//                 <MenuItem onClick={handleDeleteFolder}>Delete </MenuItem>
//             </Menu>
//         </DataContainer>
//     );
// };
// export default Data;













// import React, { useState, useEffect } from 'react';
// import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Box from '@material-ui/core/Box';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import { db } from '../firebase';
// import firebase from 'firebase/app';
// import { Menu, MenuItem, Button, IconButton, Tooltip, CircularProgress, Typography } from '@material-ui/core';
// import FolderIcon from '@material-ui/icons/Folder';
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
// import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
// import styled from 'styled-components';
// import { useTheme, useMediaQuery } from '@material-ui/core';
// import HomeIcon from '@material-ui/icons/Home';
// import AddIcon from '@material-ui/icons/Add';
// import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
// import UploadFileIcon from '@material-ui/icons/CloudUpload';
// import Welcome from './Welcome';

// // Styled components
// const DataContainer = styled.div`
//     width: 1140px;
//     height: 1vh;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     @media (max-width: 768px) {
//         padding: 10px;
//     }
//     @media (max-width: 480px) {
//         width: 100%;
//         height: 2vh;
//         padding: 0px 0px;
//     }
// `;

// const DataHeader = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     height: 20px;
//     padding: 20px;
//     padding-bottom: 4px;
//     border-bottom: 0;
    
//     .headerLeft {
//         display: flex;
//         align-items: center;
//     }
//     .headerRight {
//         display: flex;
//         align-items: center;
//         position: relative;
//     }
//     @media (max-width: 768px) {
//         flex-direction: column;
//         align-items: flex-start;
//     }
//     @media (max-width: 480px) {
//         flex-direction: column;
//         align-items: flex-start;
//         height: 80px;
//         padding: 2px;
//     }
// `;

// const Breadcrumbs = styled.div`
//     margin-bottom: 1px;
//     font-size: 18px;
//     display: flex;
//     align-items: center;
//     gap: 5px;
//     flex-wrap: wrap;
//     @media (max-width: 768px) {
//         font-size: 12px;
//     }
//     @media (max-width: 480px) {
//         font-size: 12px;
//         margin-top: 0px;
//     }
// `;

// const Breadcrumb = styled.span`
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     gap: 5px;
//     &:not(:last-child)::after {
//         content: ' > ';
//     }
// `;

// const DataGrid = styled.div`
//     display: flex;
//     flex-wrap: wrap;
//     margin-top: 30px;
//     margin-bottom: 30px;
// `;

// const DataFile = styled.div`
//     text-align: left;
//     border: 1px solid rgb(204 204 204 / 46%);
//     margin: 8px;
//     min-width: 250px;
//     max-width: 150px;
//     padding: 2px;
//     padding-left: 8px;
//     border-radius: 10px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     cursor: pointer;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//     svg {
//         font-size: 26px;
//         color: #ADB8EB;
//     }
//     .moreVertIcon {
//         color: black;
//         position: absolute;
//         right: 10px;
//     }
//     p {
//         flex: 1;
//         margin-left: 20px;
//         margin-right: 5px;
//         font-size: 14px;
//         font-weight: bold;
//         color: #333;
//     }
//     &:hover {
//         background-color: #d9d9d9;
//     }
//     @media (max-width: 768px) {
//         min-width: 130px;
//         max-width: 130px;
//         padding: 2px;
//         p {
//             font-size: 12px;
//         }
//     }
//     @media (max-width: 480px) {
//         min-width: 140px;
//         max-width: 10px;
//         height: 35px;
//         padding: 0;
//         margin-top: 1px;
//         padding-left: 25px;
//         svg {
//             margin-right: 2px;
//         }
//         p {
//             font-size: 12px;
//             padding-right: 0;
//             margin-left: 2px;
//         }
//     }
// `;

// const NewButton = styled(Button)`
//     && {
//         float: left;
//         @media (max-width: 768px) {
//             float: right;
//             margin-right: 10px;
//         }
//         @media (max-width: 480px) {
//             float: right;
//             margin-left: 10px;
//             width: 20px;
//             font-size: 12px;
//         }
//     }
// `;

// const StyledTable = styled(Table)`
//     && {
//         border-collapse: collapse;
//         width: 100%;
//         font-size: 12px;
//         @media (max-width: 768px) {
//             width: 90%;
//             margin-left: 20px;
//         }
//         @media (max-width: 480px) {
//             width: 95%;
//             font-size: 6px;
//             border-collapse: collapse;
//             margin: 8px;
//         }
//     }
//     td {
//         border: none;
//         padding: 1px;
//         text-align: left;
//         font-size: 12px;
//         background-color: #ffffff;
//         border-bottom: 1px solid #e0e0e0;
//         @media (min-width: 1024px) {
//             font-size: 16px;
//         }
//     }
//     th {
//         background-color: #ADB8EB;
//         font-weight: semibold;
//         text-align: left;
//         @media (min-width: 1024px) {
//             font-size: 16px;
//         }
//     }
//     tr {
//         margin-bottom: 20px;
//     }
//     tr:hover td {
//         background-color: #f9f9f9;
//     }
// `;

// const FileNameCell = styled(TableCell)`
//     font-weight: normal;
//     color: #333;
//     @media (min-width: 1024px) {
//         min-width: 400px;
//     }
//     @media (min-width: 480px) {
//         min-width: 400px;
//     }
// `;

// const FileSizeCell = styled.td`
//     text-align: right;
//     @media (min-width: 1024px) {
//         min-width: 200px;
//     }
// `;

// const StyledMenuItem = styled(MenuItem)``;

// const StyledLabel = styled.label``;

// const StorageInfo = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     margin-top: 20px;
//     margin-right: 20px;

//     @media (max-width: 768px) {
//         justify-content: flex-start;
//         margin-left: 20px;
//     }
// `;

// const formatFileSize = (size) => {
//     const GB = 1000 * 1000 * 1000; // 1 GB in bytes
//     if (size < 1000) return `${size} B`;
//     else if (size < 1000 * 1000) return `${(size / 1000).toFixed(2)} KB`;
//     else if (size < GB) return `${(size / (1000 * 1000)).toFixed(2)} MB`;
//     else return `${(size / GB).toFixed(2)} GB`;
// };

// const Data = () => {
//     const [files, setFiles] = useState([]);
//     const [currentFolderId, setCurrentFolderId] = useState(null);
//     const [breadcrumbs, setBreadcrumbs] = useState([]);
//     const [menuAnchorEl, setMenuAnchorEl] = useState(null);
//     const [folderAnchorEl, setFolderAnchorEl] = useState(null);
//     const [selectedFolderId, setSelectedFolderId] = useState(null);
//     const isSmallScreen = useMediaQuery('(max-width: 768px)');
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [open, setOpen] = useState(false);
//     const [uploadProgress, setUploadProgress] = useState(0);
//     const [isUploading, setIsUploading] = useState(false);
//     const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
//     const [folderPassword, setFolderPassword] = useState('');
//     const [currentFolderPassword, setCurrentFolderPassword] = useState(null);
//     const [totalStorage, setTotalStorage] = useState(0);
//     const [usedStorage, setUsedStorage] = useState(0);
//     const maxStorage = 1000 * 1000 * 1000; // 1 GB in bytes

//     const sortedFiles = [...files].sort((a, b) => {
//         return b.data.timestamp?.toDate() - a.data.timestamp?.toDate();
//     });

//     useEffect(() => {
//         db.collection("myfiles")
//             .where("parent", "==", currentFolderId)
//             .onSnapshot(snapshot => {
//                 setFiles(snapshot.docs.map(doc => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 })));
//             });
//     }, [currentFolderId]);

//     useEffect(() => {
//         const fetchBreadcrumbs = async () => {
//             let breadcrumbs = [];
//             let folderId = currentFolderId;

//             while (folderId) {
//                 const doc = await db.collection("myfiles").doc(folderId).get();
//                 const folderData = doc.data();

//                 if (folderData) {
//                     breadcrumbs.unshift({
//                         id: folderId,
//                         name: folderData.filename || 'Unnamed',
//                         isFolder: folderData.isFolder || false,
//                     });
//                     folderId = folderData.parent;
//                 } else {
//                     folderId = null;
//                 }
//             }

//             breadcrumbs.unshift({
//                 id: null,
//                 name: 'Home',
//                 isFolder: true,
//                 isHome: true,
//             });

//             setBreadcrumbs(breadcrumbs);
//         };

//         fetchBreadcrumbs();
//     }, [currentFolderId]);

//     useEffect(() => {
//         const calculateTotalStorage = async () => {
//             const snapshot = await db.collection("myfiles").get();
//             let total = 0;
//             snapshot.docs.forEach(doc => {
//                 const fileData = doc.data();
//                 if (fileData.size) {
//                     total += fileData.size;
//                 }
//             });
//             setTotalStorage(total);
//             setUsedStorage((total / maxStorage) * 100);
//         };

//         calculateTotalStorage();
//     }, [files]);

//     const handleBreadcrumbClick = (id) => {
//         setCurrentFolderId(id);
//     };

//     const handleNewButtonClick = (event) => {
//         setMenuAnchorEl(event.currentTarget);
//     };

//     const handleNewMenuClose = () => {
//         setMenuAnchorEl(null);
//     };

//     const handleCreateFolder = () => {
//         const folderName = prompt("Enter folder name:");
//         if (folderName) {
//             db.collection("myfiles").add({
//                 filename: folderName,
//                 isFolder: true,
//                 timestamp: firebase.firestore.FieldValue.serverTimestamp(),
//                 parent: currentFolderId || null,
//             });
//         }
//         handleNewMenuClose();
//     };

//     const handleFileUpload = () => {
//         const file = document.getElementById('fileInput').files[0];
//         if (!file) return;

//         const storageRef = firebase.storage().ref(`files/${file.name}`);
//         const uploadTask = storageRef.put(file);

//         setIsUploading(true);
//         setOpen(true);

//         uploadTask.on(
//             'state_changed',
//             (snapshot) => {
//                 const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                 setUploadProgress(progress);
//             },
//             (error) => {
//                 console.error('Upload failed:', error);
//                 setIsUploading(false);
//                 setOpen(false);
//             },
//             () => {
//                 uploadTask.snapshot.ref.getDownloadURL().then((url) => {
//                     firebase.firestore().collection('myfiles').add({
//                         filename: file.name,
//                         fileURL: url,
//                         size: file.size,
//                         timestamp: firebase.firestore.FieldValue.serverTimestamp(),
//                         parent: currentFolderId,
//                     });
//                     setIsUploading(false);
//                     setOpen(false);
//                 });
//             }
//         );
//     };

//     const handleMoreVertClick = (event, folderId) => {
//         event.stopPropagation();
//         setFolderAnchorEl(event.currentTarget);
//         setSelectedFolderId(folderId);
//     };

//     const handleFolderMenuClose = () => {
//         setFolderAnchorEl(null);
//         setSelectedFolderId(null);
//     };

//     const handleRenameFolder = () => {
//         const newName = prompt("Enter new folder name:");
//         if (newName && selectedFolderId) {
//             db.collection("myfiles").doc(selectedFolderId).update({
//                 filename: newName,
//             });
//         }
//         handleFolderMenuClose();
//     };

//     const handleDeleteFolder = () => {
//         if (window.confirm("Are you sure you want to delete this folder?") && selectedFolderId) {
//             deleteFolderAndContents(selectedFolderId);
//         }
//         handleFolderMenuClose();
//     };

//     const deleteFolderAndContents = async (folderId) => {
//         const folderRef = db.collection("myfiles").doc(folderId);
//         const subItems = await db.collection("myfiles").where("parent", "==", folderId).get();

//         for (const subItem of subItems.docs) {
//             if (subItem.data().isFolder) {
//                 await deleteFolderAndContents(subItem.id);
//             } else {
//                 await db.collection("myfiles").doc(subItem.id).delete();
//             }
//         }
//         await folderRef.delete();
//     };

//     const handleRenameFile = (fileId) => {
//         const newName = prompt("Enter new file name:");
//         if (newName) {
//             db.collection("myfiles").doc(fileId).update({
//                 filename: newName,
//             });
//         }
//     };

//     const handleDeleteFile = (fileId) => {
//         if (window.confirm("Are you sure you want to delete this file?")) {
//             db.collection("myfiles").doc(fileId).delete();
//         }
//     };

//     const handleDownloadFile = (fileURL, filename) => {
//         fetch(fileURL)
//             .then(response => {
//                 console.log("Response status:", response.status);
//                 return response.blob();
//             })
//             .then(blob => {
//                 if (blob.size === 0) {
//                     throw new Error("Received empty blob");
//                 }
//                 const link = document.createElement('a');
//                 link.href = URL.createObjectURL(blob);
//                 link.download = filename;
//                 link.click();
//             })
//             .catch(error => console.error('Error downloading file:', error));
//     };

//     const handleViewFile = (fileURL) => {
//         window.open(fileURL, '_blank');
//         console.log("this is fileurl", fileURL);
//     };

//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//         console.log("this is event", event);
//         event.stopPropagation();
//     };

//     const handleClose = (event) => {
//         event.stopPropagation();
//         setAnchorEl(null);
//     };

//     return (
//         <DataContainer>
//             <DataHeader>
//                 <Breadcrumbs>
//                     {breadcrumbs.map((breadcrumb) => (
//                         <React.Fragment key={breadcrumb.id || 'home'}>
//                             {breadcrumb.isHome ? (
//                                 <HomeIcon style={{ fontSize: 25, marginRight: 5, color: '#e76f51' }} />
//                             ) : (
//                                 <FolderIcon style={{ fontSize: 20, marginRight: 5, color: '#e76f51' }} />
//                             )}
//                             <Breadcrumb
//                                 onClick={() => handleBreadcrumbClick(breadcrumb.id)}
//                                 style={{ color: '#e76f51' }}
//                             >
//                                 {breadcrumb.name}
//                             </Breadcrumb>
//                         </React.Fragment>
//                     ))}
//                 </Breadcrumbs>
                
//                 <div className="headerRight">
//                     <NewButton
//                         variant="contained"
//                         color="primary"
//                         style={{ backgroundColor: '#e76f51', marginRight: '45px', marginTop: '25px' }}
//                         onClick={handleNewButtonClick}
//                         startIcon={<AddIcon />}
//                     >
//                         New
//                     </NewButton>
                
//                     <Menu
//                         anchorEl={menuAnchorEl}
//                         keepMounted
//                         open={Boolean(menuAnchorEl)}
//                         onClose={handleNewMenuClose}
//                         PaperProps={{
//                             style: {
//                                 marginTop: 50,
//                             },
//                         }}
//                     >
//                         <StyledMenuItem onClick={handleCreateFolder}>
//                             <CreateNewFolderIcon style={{ marginRight: 8 }} />
//                             Create Folder
//                         </StyledMenuItem>
//                         {currentFolderId && (
//                             <div>
//                                 <StyledMenuItem>
//                                     <UploadFileIcon style={{ marginRight: 8 }} />
//                                     <StyledLabel htmlFor="fileInput">Upload File</StyledLabel>
//                                     <input
//                                         type="file"
//                                         id="fileInput"
//                                         style={{ display: 'none' }}
//                                         onChange={handleFileUpload}
//                                     />
//                                 </StyledMenuItem>
//                                 <Dialog open={open} onClose={handleClose} aria-labelledby="upload-dialog-title">
//                                     <DialogTitle id="upload-dialog-title">Uploading File</DialogTitle>
//                                     <DialogContent>
//                                         <Box sx={{ width: '100%', mt: 1 }}>
//                                             <LinearProgress
//                                                 variant="determinate"
//                                                 value={uploadProgress}
//                                                 sx={{
//                                                     height: 40,
//                                                     borderRadius: 5,
//                                                     backgroundColor: '#e0f7e0',
//                                                     '& .MuiLinearProgress-bar': {
//                                                         backgroundColor: '#4caf50',
//                                                         borderRadius: 5,
//                                                     },
//                                                 }}
//                                             />
//                                             <Typography
//                                                 variant="body2"
//                                                 color="textSecondary"
//                                                 align="center"
//                                                 style={{ marginTop: '8px' }}
//                                             >
//                                                 {Math.round(uploadProgress)}%
//                                             </Typography>
//                                         </Box>
//                                     </DialogContent>
//                                 </Dialog>
//                             </div>
//                         )} 
//                     </Menu>
//                 </div>
              
//             </DataHeader>
//             <div className='welcome_container' style={{ marginTop: '12px', alignItems:'center', justifyContent:'center' }}>
//                 <div className='welcome_content'> 
//                     <Welcome/>
//                 </div>
//             </div>
          
//             <DataGrid>
//                 {sortedFiles.filter(file => file.data.isFolder).map(file => (
//                     <DataFile key={file.id} onClick={() => setCurrentFolderId(file.id)}>
//                         <FolderIcon />
//                         <p>
//                             {isSmallScreen
//                                 ? (file.data.filename.length > 8 ? `${file.data.filename.substring(0, 8)}...` : file.data.filename)
//                                 : (file.data.filename.length > 20 ? `${file.data.filename.substring(0, 20)}...` : file.data.filename)}
//                         </p>
//                         <Tooltip title="More options">
//                             <IconButton onClick={(event) => handleMoreVertClick(event, file.id)}>
//                                 <MoreVertIcon />
//                             </IconButton>
//                         </Tooltip>
//                     </DataFile>
//                 ))}
//             </DataGrid>
          
//             {sortedFiles.filter(file => !file.data.isFolder).length > 0 ? (
//                <div className='table-container'>
//                <table className="simple-table">
//                    <thead>
//                        <tr>
//                        <th className="file-name-cell">File Name</th>
//                            <th className="hide-sm">Size</th>
//                            <th className="hide-xs">Date</th>
//                            <th>Actions</th>
//                        </tr>
//                    </thead>
//                    <tbody>
//                        {sortedFiles.filter(file => !file.data.isFolder).map(file => (
//                            <tr key={file.id} onClick={() => handleViewFile(file.data.fileURL)}>
//                                <td className="file-name-cell">
//                                    <div className="file-name">
//                                        <InsertDriveFileIcon style={{ color: '#ADB8EB' }} />
//                                        <span>{file.data.filename}</span>
//                                    </div>
//                                </td>
//                                <td className="file-size-cell hide-sm">{formatFileSize(file.data.size)}</td>
//                                <td className="hide-xs">{new Date(file.data.timestamp?.toDate()).toLocaleDateString()}</td>
//                                <td>
//                                    <div className="action-buttons">
//                                        {isSmallScreen ? (
//                                            <>
//                                                <IconButton onClick={(event) => { event.stopPropagation(); handleClick(event); }}>
//                                                    <MoreVertIcon />
//                                                </IconButton>
//                                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
//                                                    <MenuItem onClick={() => { handleRenameFile(file.id); handleClose(); }}>Rename</MenuItem>
//                                                    <MenuItem onClick={() => { handleDeleteFile(file.id); handleClose(); }}>Delete</MenuItem>
//                                                    <MenuItem onClick={() => { handleDownloadFile(file.data.fileURL, file.data.filename); handleClose(); }}>Download</MenuItem>
//                                                </Menu>
//                                            </>
//                                        ) : (
//                                            <>
//                                                <Tooltip title="Rename">
//                                                    <IconButton onClick={(event) => { event.stopPropagation(); handleRenameFile(file.id); }} className="action-icons">
//                                                        <EditIcon />
//                                                    </IconButton>
//                                                </Tooltip>
//                                                <Tooltip title="Delete">
//                                                    <IconButton onClick={(event) => { event.stopPropagation(); handleDeleteFile(file.id); }} className="action-icons">
//                                                        <DeleteIcon />
//                                                    </IconButton>
//                                                </Tooltip>
//                                                <Tooltip title="Download">
//                                                    <IconButton onClick={(event) => { event.stopPropagation(); handleDownloadFile(file.data.fileURL, file.data.filename); }} className="action-icons">
//                                                        <ArrowDownwardIcon />
//                                                    </IconButton>
//                                                </Tooltip>
//                                            </>
//                                        )}
//                                    </div>
//                                </td>
//                            </tr>
//                        ))}
//                    </tbody>
//                </table>
//            </div>
//             ) : (currentFolderId && <p>No files</p>)} 

//             <Menu
//                 anchorEl={folderAnchorEl}
//                 keepMounted
//                 open={Boolean(folderAnchorEl)}
//                 onClose={handleFolderMenuClose}
//                 PaperProps={{
//                     style: {
//                         marginTop: 60,
//                     },
//                 }}
//             >
//                 <MenuItem onClick={handleRenameFolder}>Rename</MenuItem>
//                 <MenuItem onClick={handleDeleteFolder}>Delete</MenuItem>
//             </Menu>
//         </DataContainer>
//     );
// };

// export default Data;






































import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { db } from '../firebase';
import firebase from 'firebase/app';
import { Menu, MenuItem, Button, IconButton, Tooltip } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useTheme, useMediaQuery } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add'; // For the New button
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'; // For the Create Folder option
import UploadFileIcon from '@material-ui/icons/CloudUpload'; // For the Upload File option
import Welcome from './Welcome';
import Sidebar from './Sidebar';
// Styled components (keep your existing styled components here)
const DataContainer = styled.div`

 
    width: 1140px;
    height: 1vh;
    align-items: center;
justify-content: center;
   
     
       position: relative;
     @media (max-width: 768px) {
        padding: 10px;
    }
         @media (max-width: 480px) {
        width: 100%;
    height: 2vh;
     padding: 0px 0px;
    }
`;

const DataHeader = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    padding: 20px;
    padding-bottom: 4px;
    border-bottom: 0; /* Remove horizontal line */
    
    .headerLeft {
        display: flex;
        align-items: center;
    }
    .headerRight {
        display: flex;
        align-items: center;
        position: relative; /* Ensure dropdown is positioned correctly */
    }
          @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
    }
            @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
         height: 80px;
    padding: 2px;
    }
`;

const Breadcrumbs = styled.div`
    margin-bottom: 1px;
    
    font-size: 18px; /* Adjusted font size for smaller screens */
    
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap; /* Wrap breadcrumbs for smaller screens */
      @media (max-width: 768px) {
       font-size: 12px;
       
    }
       flex-wrap: wrap; /* Wrap breadcrumbs for smaller screens */
      @media (max-width: 480px) {
       font-size: 12px;
        margin-top: 0px;
        
       
    }
`;

const Breadcrumb = styled.span`

    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    &:not(:last-child)::after {
        content: ' > ';
        color:#e76f51;
    }
`;

const DataGrid = styled.div`

    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 35px;
      
`;

const DataFile = styled.div`
   
    text-align: left;
    
    border: 1px solid rgb(204 204 204 / 46%);
    margin: 8px; /* Margin for spacing */
    min-width: 250px; /* Increased width for larger screens */
    max-width: 150px; /* Limit maximum width */
    padding: 2px; /* Increased padding */
     padding-left: 8px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    // background-color: #ffffff; /* White background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    svg {
        font-size: 26px; /* Icon size */
        color: #ADB8EB; /* Icon color */
    }
    .moreVertIcon {
        color: black; /* MoreVert icon color */
        position: absolute; /* Fix the position */
        right: 10px; /* Align to the right */
    }
    p {
        flex: 1;
        margin-left: 20px; /* Margin for text */
        margin-right: 5px; /* Margin for text */
        font-size: 14px; /* Font size for larger screens */
        font-weight: bold;
        color: #333;
    }
    &:hover {
        background-color: #d9d9d9; /* Light gray background on hover */
    }
    @media (max-width: 768px) {
        min-width: 130px; /* Reduced width for medium screens */
        max-width: 130px;
        padding: 2px; /* Reduced padding */
        p {
            font-size: 12px; /* Reduced font size */
        }
            
    }
@media (max-width: 480px) {
    min-width: 140px; /* Further reduced width for small screens */
    max-width: 10px;
    height: 35px;
    padding: 0; /* Remove all padding */
    margin-top: 1px;
    padding-left: 25px; /* Adjust the left padding for folder */
   
    svg {
        margin-right: 2px; /* Reduce space between icon and text */
    }

    p {
        font-size: 12px;
        padding-right: 0; /* No padding on the right */
        margin-left: 2px; /* Reduce margin between SVG and text */
    }
}


`;
const NewButton = styled(Button)`
    && {
       
        float: left;

        @media (max-width: 768px) {
            float: right; /* Shift the button to the right */
            margin-right: 10px; /* Add some margin for spacing */
        }

        @media (max-width: 480px) {
            float: right;
           margin-left:10px;
            width:20px;
            font-size:12px;
        }
    }
`;




const StyledTable = styled(Table)`
    && {
     
        border-collapse: collapse;
        width: 100%; /* Full width on larger screens */
       
        font-size: 12px;
      

        @media (max-width: 768px) {
            width: 90%; /* Reduce width for tablets */
            /* Margin from the right */
             margin-left:20px;
        }

        @media (max-width: 480px) {
        
            width: 95%;
            
            font-size: 6px; 
             
             border-collapse: collapse;
            margin:8px;
        }
    }

    td {
  
        border: none;
        padding: 1px;
        text-align: left;
        font-size: 12px;
        background-color: #ffffff;
        border-bottom: 1px solid #e0e0e0;
        
        @media (min-width: 1024px) {
           font-size: 16px;
           
        }
    }

    th {
      
     background-color:#ADB8EB;
        font-weight: semibold;
        text-align: left;
    
        
        @media (min-width: 1024px) {
           font-size: 16px;
        }
    }

    tr {
    
        margin-bottom: 20px;
    }

    tr:hover td {
        background-color: #f9f9f9;
    }
`;

const FileNameCell = styled(TableCell)`

    font-weight: normal;
    color: #333;
   
    @media (min-width: 1024px) {
        min-width: 400px; /* Adjust this value to set the desired width on large screens */
      
    }
           @media (min-width: 480px) {
        min-width: 400px; /* Adjust this value to set the desired width on large screens */
    }
`;
const FileSizeCell = styled.td`
  text-align: right;

  @media (min-width: 1024px) {
  min-width: 200px;
  }
`;




const StyledMenuItem = styled(MenuItem)`

  
`;

const StyledLabel = styled.label`

`;  
const Data = () => {
    const [files, setFiles] = useState([]);
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [folderAnchorEl, setFolderAnchorEl] = useState(null);
    const [selectedFolderId, setSelectedFolderId] = useState(null);
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        db.collection("myfiles")
            .where("parent", "==", currentFolderId)
            .onSnapshot(snapshot => {
                setFiles(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data(),
                })));
            });
    }, [currentFolderId]);

    useEffect(() => {
        const fetchBreadcrumbs = async () => {
            let breadcrumbs = [];
            let folderId = currentFolderId;

            while (folderId) {
                const doc = await db.collection("myfiles").doc(folderId).get();
                const folderData = doc.data();

                if (folderData) {
                    breadcrumbs.unshift({
                        id: folderId,
                        name: folderData.filename || 'Unnamed',
                        isFolder: folderData.isFolder || false,
                    });
                    folderId = folderData.parent;
                } else {
                    folderId = null;
                }
            }

            breadcrumbs.unshift({
                id: null,           
                // name: 'Home',
                // isFolder: true,
                isHome: true,
            });

            setBreadcrumbs(breadcrumbs);
        };

        fetchBreadcrumbs();
    }, [currentFolderId]);

    const handleBreadcrumbClick = (id) => {
        setCurrentFolderId(id);
    };

    const handleNewButtonClick = (event) => {
        if (currentFolderId) {
            setMenuAnchorEl(event.currentTarget);
            setShowMenu(true);
        } else {
            handleCreateFolder();
        }
    };

    const handleNewMenuClose = () => {
        setMenuAnchorEl(null);
        setShowMenu(false);
    };
    const resetToHome = () => {
        setCurrentFolderId(null);
    };
    const handleCreateFolder = () => {
        const folderName = prompt("Enter folder name:");
        if (folderName) {
            db.collection("myfiles").add({
                filename: folderName,
                isFolder: true,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                parent: currentFolderId || null,
            });
        }
        handleNewMenuClose();
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const storageRef = firebase.storage().ref(`files/${file.name}`);
        const uploadTask = storageRef.put(file);

        setIsUploading(true);
        setOpen(true);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error('Upload failed:', error);
                setIsUploading(false);
                setOpen(false);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                    firebase.firestore().collection('myfiles').add({
                        filename: file.name,
                        fileURL: url,
                        size: file.size,
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                        parent: currentFolderId,
                    });
                    setIsUploading(false);
                    setOpen(false);
                });
            }
        );
    };

    const handleMoreVertClick = (event, folderId) => {
        event.stopPropagation();
        setFolderAnchorEl(event.currentTarget);
        setSelectedFolderId(folderId);
    };

    const handleFolderMenuClose = () => {
        setFolderAnchorEl(null);
        setSelectedFolderId(null);
    };

    const handleRenameFolder = () => {
        const newName = prompt("Enter new folder name:");
        if (newName && selectedFolderId) {
            db.collection("myfiles").doc(selectedFolderId).update({
                filename: newName,
            });
        }
        handleFolderMenuClose();
    };

    const handleDeleteFolder = () => {
        if (window.confirm("Are you sure you want to delete this folder?") && selectedFolderId) {
            deleteFolderAndContents(selectedFolderId);
        }
        handleFolderMenuClose();
    };

    const deleteFolderAndContents = async (folderId) => {
        const folderRef = db.collection("myfiles").doc(folderId);
        const subItems = await db.collection("myfiles").where("parent", "==", folderId).get();

        for (const subItem of subItems.docs) {
            if (subItem.data().isFolder) {
                await deleteFolderAndContents(subItem.id);
            } else {
                await db.collection("myfiles").doc(subItem.id).delete();
            }
        }
        await folderRef.delete();
    };

    const handleRenameFile = (fileId) => {
        const newName = prompt("Enter new file name:");
        if (newName) {
            db.collection("myfiles").doc(fileId).update({
                filename: newName,
            });
        }
    };

    const handleDeleteFile = (fileId) => {
        if (window.confirm("Are you sure you want to delete this file?")) {
            db.collection("myfiles").doc(fileId).delete();
        }
    };

    const handleDownloadFile = (fileURL, filename) => {
        fetch(fileURL)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            })
            .catch(error => console.error('Error downloading file:', error));
    };

    const handleViewFile = (fileURL) => {
        window.open(fileURL, '_blank');
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const formatFileSize = (size) => {
        if (size < 1024) return `${size} B`;
        else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
        else return `${(size / 1048576).toFixed(2)} MB`;
    };

    return (
        <DataContainer>
            <DataHeader>
                {/* <Breadcrumbs>
                    {breadcrumbs.map((breadcrumb) => (
                        <React.Fragment key={breadcrumb.id || 'home'}>
                            {breadcrumb.isHome ? (
                                <HomeIcon style={{ fontSize: 25, marginRight: 5, color: '#e76f51' }} />
                            ) : (
                                <FolderIcon style={{ fontSize: 20, marginRight: 5, color: '#e76f51' }} />
                            )}
                            <Breadcrumb
                                onClick={() => handleBreadcrumbClick(breadcrumb.id)}
                                style={{ color: '#e76f51' }}
                            >
                                {breadcrumb.name}
                            </Breadcrumb>
                        </React.Fragment>
                    ))}
                </Breadcrumbs> */}
                <Breadcrumbs>
                    {breadcrumbs.map((breadcrumb,index) => (
                        <React.Fragment key={breadcrumb.id || 'home'}>
                           
                            {breadcrumb.isHome ? (
                              <Breadcrumb onClick={() => handleBreadcrumbClick(null)}>
                              <HomeIcon style={{ fontSize: 25, color: '#e76f51', cursor: 'pointer' }} />
                          </Breadcrumb>
                            ) : (
                                <Breadcrumb onClick={() => handleBreadcrumbClick(breadcrumb.id)}>
                                <FolderIcon style={{ fontSize: 20, marginRight: 5, color: '#e76f51' }} />
                                <span style={{ color: '#e76f51' }}>{breadcrumb.name}</span>
                            </Breadcrumb>
                        )}
                    </React.Fragment>
                    ))}
                </Breadcrumbs> 
                
                
                <div className="headerRight">
                    <NewButton
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#e76f51', marginRight: '45px', marginTop: '25px' }}
                        onClick={handleNewButtonClick}
                        startIcon={<AddIcon />}
                    >
                        New
                    </NewButton>
                
                    <Menu
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={showMenu}
                        onClose={handleNewMenuClose}
                        PaperProps={{
                            style: {
                                marginTop: 50,
                            },
                        }}
                    >
                        <StyledMenuItem onClick={handleCreateFolder}>
                            <CreateNewFolderIcon style={{ marginRight: 8 }} />
                            Create Folder
                        </StyledMenuItem>
                        <StyledMenuItem>
                            <UploadFileIcon style={{ marginRight: 8 }} />
                            <StyledLabel htmlFor="fileInput">Upload File</StyledLabel>
                            <input
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                            />
                        </StyledMenuItem>
                    </Menu>
                </div>
            </DataHeader>

            <div className='welcome_container' style={{ marginTop: '12px', alignItems:'center', justifyContent:'center' }}>
                <div className='welcome_content'> 
                    <Welcome/>
                </div>
            </div>
            {/* <Sidebar /> */}
          
            <DataGrid>
                {files.filter(file => file.data.isFolder).map(file => (
                    <DataFile key={file.id} onClick={() => setCurrentFolderId(file.id)}>
                        <FolderIcon />
                        <p>
                            {isSmallScreen
                                ? (file.data.filename.length > 8 ? `${file.data.filename.substring(0, 8)}...` : file.data.filename)
                                : (file.data.filename.length > 20 ? `${file.data.filename.substring(0, 20)}...` : file.data.filename)}
                        </p>
                        <Tooltip title="More options">
                            <IconButton onClick={(event) => handleMoreVertClick(event, file.id)}>
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                    </DataFile>
                ))}
            </DataGrid>
          
            {files.filter(file => !file.data.isFolder).length > 0 ? (
    <table className="simple-table">
        <thead>
            <tr>
                <th>File Name</th>
                <th>Size</th>
                <th>Date</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {files.filter(file => !file.data.isFolder).map(file => (
                <tr key={file.id} onClick={() => handleViewFile(file.data.fileURL)}>
                    <td data-label="File Name">
                        <div className="file-name">
                            <InsertDriveFileIcon style={{ color: ' #ADB8EB' }} />
                            <span>
                                {isSmallScreen
                                    ? (file.data.filename.length > 8 ? `${file.data.filename.substring(0, 8)}...` : file.data.filename)
                                    : file.data.filename}
                            </span>
                        </div>
                    </td>
                    <td data-label="Size">{formatFileSize(file.data.size)}</td>
                    <td data-label="Date">{new Date(file.data.timestamp?.toDate()).toLocaleDateString()}</td>
                    <td data-label="Actions">
                        <div className="action-buttons">
                            {isSmallScreen ? (
                                <>
                                    <IconButton onClick={(event) => { event.stopPropagation(); handleClick(event); }}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                                        <MenuItem onClick={() => { handleRenameFile(file.id); handleClose(); }}>  <EditIcon /></MenuItem>
                                        <MenuItem onClick={() => { handleDeleteFile(file.id); handleClose(); }}><DeleteIcon /></MenuItem>
                                        <MenuItem onClick={() => { handleDownloadFile(file.data.fileURL, file.data.filename); handleClose(); }}> <ArrowDownwardIcon /></MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <>
                                    <Tooltip title="Rename">
                                        <IconButton onClick={(event) => { event.stopPropagation(); handleRenameFile(file.id); }} className="action-icons">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton onClick={(event) => { event.stopPropagation(); handleDeleteFile(file.id); }} className="action-icons">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Download">
                                        <IconButton onClick={(event) => { event.stopPropagation(); handleDownloadFile(file.data.fileURL, file.data.filename); }} className="action-icons">
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </div>
                    </td>
                  
                   
                </tr>
            ))}
        </tbody>
    </table>
) : (currentFolderId && <p>No files</p>)}

            <Menu
                anchorEl={folderAnchorEl}
                keepMounted
                open={Boolean(folderAnchorEl)}
                onClose={handleFolderMenuClose}
                PaperProps={{
                    style: {
                        marginTop: 60,
                    },
                }}
            >
                <MenuItem onClick={handleRenameFolder}>Rename</MenuItem>
                <MenuItem onClick={handleDeleteFolder}>Delete</MenuItem>
            </Menu>

            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="upload-dialog-title">
                <DialogTitle id="upload-dialog-title">Uploading File</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', mt: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            value={uploadProgress}
                            sx={{
                                height: 40,
                                borderRadius: 5,
                                backgroundColor: '#e0f7e0',
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: '#4caf50',
                                    borderRadius: 5,
                                },
                            }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            style={{ marginTop: '8px' }}
                        >
                            {Math.round(uploadProgress)}%
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </DataContainer>
    );
};

export default Data;